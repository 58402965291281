<template>
  <v-container fluid class="full-fill white">
    <!-- Row 1 - Hero -->
    <v-row class="full-fill">
      <v-img class="w-100" src="@/assets/img/bg-heroes.jpg">
        <v-img class="postcard-images d-none d-sm-flex" :src="require(`@/assets/img/${bgImageURL}`)" style="z-index: -1;"></v-img>
        <v-row class="ma-0 pa-10 w-100">
          <v-col cols="3" class="d-none d-sm-flex">
          </v-col>
          <v-col align="center" class="align-space-between pa-0 white--text">
            <h2 class="header-text">HELP US SHARE</h2>
            <h2 class="header-text mb-10">YOUR VOICE AND EXPERIENCE</h2>
            <p class="header-text2 mb-10">Honor Tag is building the largest collection of active duty and veterans' stories on the web. It's an ambitious task and we need you! Join for free today and help us reach 5,000 profiles.</p>
            <v-list-item class="justify-center mb-5"><v-btn color="blue darken-3" min-width="300" x-large :elevation="23" class="white--text mb-3" to="/story-create">CREATE A VETERAN STORY</v-btn></v-list-item>
            <v-list-item class="justify-center mb-5"><v-btn color="blue darken-3" min-width="300" x-large :elevation="23" class="white--text mb-3" :to="{ name: 'SearchResults', params: { routedFromLandingPage: true } }">SEARCH FOR VETERANS</v-btn></v-list-item>
            <v-list-item class="justify-center"><v-btn color="blue darken-3" min-width="300" x-large :elevation="23" class="white--text" href="https://youtu.be/Yd84hpv0V2E" target="_blank">LEARN ABOUT US</v-btn></v-list-item>
          </v-col>
          <v-col cols="3" class="d-none d-sm-flex">
          </v-col>
        </v-row>
      </v-img>
    </v-row>
    <!-- Row 2 - Foundation -->
    <v-row class="flex-wrap ma-0 pa-0 pt-5 w-100" align="center">
      <v-col cols="2" class="d-none d-sm-flex"></v-col>
      <v-col class="col-sm-4 pr-sm-7">
        <v-img src="@/assets/img/Splash_FaceDogtag.jpg"></v-img>
      </v-col>
      <v-col align="center" class="col-sm-4 pl-sm-7">
        <v-img max-width="140" max-height="140" src="@/assets/img/Splash_IconSmall.jpg" class="d-none d-sm-flex mb-3"></v-img>
        <h3>Veterans' Stories are the Foundation of Honor Tag</h3>
        <p class="ma-0 pa-0">In mere minutes you can tell your story or share memories about a living or fallen hero.</p>
      </v-col>
      <v-col cols="2" class="d-none d-sm-flex"></v-col>
    </v-row>
    <!-- Row 3 - Discover -->
    <v-row class="flex-row-reverse ma-0 pa-0 pt-5 w-100" align="center">
      <v-col cols="2" class="d-none d-sm-flex"></v-col>
      <v-col class="col-sm-4 pl-sm-7">
        <v-img src="@/assets/img/Splash_FacesMid.jpg"></v-img>
      </v-col>
      <v-col align="center" class="col-sm-4 pr-sm-7">
        <v-img max-width="140" max-height="140" src="@/assets/img/Splash_IconSmall.jpg" class="d-none d-sm-flex mb-3"></v-img>
        <h3>Honor Tag Will Help You Discover Servicemembers' Stories</h3>
        <p>We make it easy to save all the information about the life of your veteran in one easy to view place.</p>
        <p>Deployments, hometown, military branch, unit, and much more; all simple to upload for safekeeping.</p>
      </v-col>
      <v-col cols="2" class="d-none d-sm-flex"></v-col>
    </v-row>
    <!-- Row 4 - Shadowbox -->
    <v-row class="ma-0 pa-0 pt-5 w-100" align="center">
      <v-col cols="2" class="d-none d-sm-flex">
      </v-col>
      <v-col align="center" class="col-sm-4 pr-sm-7">
        <v-img max-width="140" max-height="140" src="@/assets/img/Splash_IconSmall.jpg"></v-img>
        <h3 class="pb-5">Construct a Shadowbox to Reflect the Accomplishments of Your Servicemember and Share It With the World</h3>
        <v-img src="@/assets/img/Splash_Shadowbox_Right.jpg"></v-img>
      </v-col>
      <v-col align="center" class="col-sm-4 pl-sm-7">
        <v-img class="d-none d-sm-flex" src="@/assets/img/Splash_Shadowbox_Left.jpg"></v-img>
        <p class="pt-5">Select from available medals, ribbons, rank insignia and more to form a beautiful memorial safely stored on our site. Access it from anywhere in the world to admire the accomplishments displayed in the shadowbox.</p>
        <h3>Customizable Shadowboxes Help You Celebrate Your Veteran</h3>
        <p>From rank patches to ribbons and medals, our Shadowbox Builder makes it easy to create a visual reminder of all of a servicemember's achievements. Share with your friends and family.</p>
      </v-col>
      <v-col cols="2" class="d-none d-sm-flex">
      </v-col>
    </v-row>
    <!-- Row 5 - Images -->
    <v-row class="full-fill">
      <v-img src="@/assets/img/Splash_ThreePhotoBottom.jpg"></v-img>
    </v-row>
    <!-- Row 6 - Mailchimp -->
    <v-row class="full-fill">
      <v-col cols="4" class="d-none d-sm-flex"></v-col>
      <v-col align="center">
        <h2 class="mt-3">Sign Up for Email Updates</h2>
        <a :href="'https://honor-tag.us4.list-manage.com/subscribe/post?u=ef8b7efaf362a93062760e7f4&amp;id=6361f1b8b8'" target="_blank" style="text-decoration: none;">
          <v-btn color="blue darken-3 white--text" class="mt-3">
            Sign Up
          </v-btn>
        </a>
      </v-col>
      <v-col cols="4" class="d-none d-sm-flex"></v-col>
    </v-row>
    <!-- Row 7 - Footer -->
    <v-row class="full-fill">
      <v-col cols="4" class="d-none d-sm-flex"></v-col>
      <v-col align="center" class="pb-10 pt-5">
        <h3 class="pb-5">As of 2014, the U.S. government estimates that there are 45 million deceased veterans, 22 million living veterans, and 83,000 veterans classified as missing or prisoners of war.</h3>
        <h1>THEY ARE NOT FORGOTTEN.</h1>
      </v-col>
      <v-col cols="4" class="d-none d-sm-flex"></v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Home',

    components: {
      //
    },

    data () {
      return {
        bgImageURL: '',
        bgImageURLs: ['heroes1.png', 'heroes2.png', 'heroes3.png']
      }
    },

    created () {
      const randIndex = Math.floor(Math.random() * Math.floor(this.bgImageURLs.length))
      this.bgImageURL = this.bgImageURLs[randIndex]
    },

    methods: {
      handleClickedCreate () {
        
      },

      handleClickedSearch () {
        
      },
    }
  }
</script>

<style scoped>
  h2 {
    font-size: 2.5em;
  }

  p {
    font-size: 1.25em;
  }

  .full-fill {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }

  .header-text {
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: center;
    text-shadow: 4px 3px 3px black;
  }

  .header-text2 {
    text-shadow: 2px 2px 2px black;
  }

  .postcard-images {
    position: absolute;
    right: 0;
    top:  0;
  }

  @media screen and (max-width: 600px) {
    h2 {
      font-size: 2em;
    }
  }
</style>
